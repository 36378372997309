<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p
									data-identifyelement="181"
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									Choose your favorite player and follow our guide to get started:
								</p>
								<p
									data-identifyelement="182"
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									data-identifyelement="184"
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									1.

									<router-link :to="{ name: 'howDoIAddToVlcPlayer' }" class="text-blue-400"><strong>VLC</strong></router-link>
								</p>
								<p
									data-identifyelement="185"
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									2.
									<router-link :to="{ name: 'howDoIAddToKodi' }" class="text-blue-400"><strong>Kodi</strong></router-link>
								</p>
								<div
									data-identifyelement="188"
									dir="ltr"
									style="
										box-sizing: border-box;
										word-break: normal;
										overflow-wrap: break-word;
										font-size: 13px;
										line-height: 18px;
										color: rgb(24, 50, 71);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									3. <a :href="$store.state.brandInfo.playerLink">WebPlayer</a>
								</div>
								<p dir="ltr"><br /></p>
								<p dir="ltr"><br /></p>
								<p dir="ltr">
									<span style="background-color: rgb(247, 218, 100)"><strong dir="ltr">PLEASE NOTE.</strong></span>
								</p>
								<p dir="ltr">
									<span style="background-color: rgb(247, 218, 100)"><br /></span>
								</p>
								<p dir="ltr">
									<span style="background-color: rgb(247, 218, 100)"
										><strong dir="ltr">&nbsp;WEBPLAYER IS CURRENTLY IN BETA AND NOT ALL FEATURES WILL WORK INCLUDING SOUND ON TV SHOWS.</strong></span
									>
								</p>
								<p dir="ltr"><br /></p>
								<p dir="ltr"><br /></p>
								<p dir="ltr">
									<span style="background-color: rgb(247, 218, 100)"><strong dir="ltr">USE WEBPLAYER MAINLY FOR LIVE TV ONLY.</strong></span>
								</p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'pcLaptopPcMacbookWebInterface',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
